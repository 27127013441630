import React from 'react'
import './Favorite.css'
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap"
import food from '../../Assets/Images/ab.jpg'
import food1 from '../../Assets/Images/th.jpg'
import food2 from '../../Assets/Images/th (4).jpg'
import food3 from '../../Assets/Images/th (8).jpg'
import food4 from '../../Assets/Images/th (5).jpg'
import food5 from '../../Assets/Images/th (7).jpg'
import {BsFillStarFill} from 'react-icons/bs'
import {FcLike} from 'react-icons/fc'

function Favorite() {
  return (
    <div>
      
      
        <div className='pastdiv'>
          <Row>
          <Col xs={12}>
                <div style={{marginLeft:10,fontWeight:600,fontSize:25,color: '#7c003f', marginTop:30, marginBottom:30}}>
                  Favourites
                </div>
                <Row> 
                <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                                
                            </div>  
                      </div>
                  </Col>

                  <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food1} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                               
                            </div>  
                      </div>
                  </Col>

                  <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food2} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                               
                            </div>  
                      </div>
                  </Col>
                </Row>


                <Row>  
                    <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food3} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                               
                            </div>  
                      </div>
                  </Col>

                  <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food4} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                              
                            </div>  
                      </div>
                  </Col>

                  <Col xs={12} md={4} > 
                      <div className='imgfst1'>
                      <img className='fdimg' src={food5} style={{width:'100%'}} />
                            <div className='descrpt'>
                                <div className='kalfc'> 
                                <p>Mall of Abayas</p>
                                <FcLike />
                                </div>
                                <div className='strvewrs'>
                                    <div className='strvew'>
                                        <div>
                                            <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                            <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        </div>
                                        <div className='review'>
                                            32 Reviews
                                        </div>      
                                    </div> 
                                    
                                </div>
                               
                            </div>  
                      </div>
                  </Col>
                </Row>
                  
            </Col>
          </Row>
        </div>
    
    </div>
  )
}

export default Favorite

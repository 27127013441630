import React, {useState} from 'react'
import './Accounts.css'
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap"
import {IoMdContact,IoReloadOutline,IoLogOutOutline} from 'react-icons/io';
import {MdReplay, MdLogout} from 'react-icons/md'
import frame from '../../Assets/Images/Frame.png' 
import {FaEdit,FaRegHeart} from 'react-icons/fa'
import fry3 from '../../Assets/Images/fry3.jpg'
import food from '../../Assets/Images/food.jpg'
import {BsFillStarFill} from 'react-icons/bs'
import {FcLike} from 'react-icons/fc'
import TextField from '@mui/material/TextField';
import Order from '../../Components/Order/Order'
import Favorite from '../../Components/Favorite/Favorite'

function Accounts() {
    const [order, setOrder] = useState(true);
  return (
    <div style={{marginTop:25}}>
      <Container>
      <Row>
              <Col xs={12} md={4}>
                  <div>
                      <p className='seldt'>My Account</p>
                  </div>
              </Col>
              <Col xs={12} md={5}>
                  <div className='metfd'>
                      <img className='metlog' src={frame} style={{marginLeft: 30}} />
                      <p className='metfdname'>FASHION STORE</p>
                  </div>
              </Col>
              <Col xs={12} md={3}>
                  <div className='contname'>
                      <IoMdContact size={24} style={{color:'#7c003f'}}/>
                      <p className='seldt'>Dawid Malan</p>
                  </div>
              </Col>
              <div className='dawmin'>
                <div>
                    <div style={{fontSize:20, fontWeight:700}}>Dawid Malan</div>
                    <div style={{fontWeight:500}}>7536421804</div>
                    <div>dawidmalan3452@gmail.com</div> 
                </div>
                <div>
                    <div className='edit'>
                      <FaEdit size={15} style={{marginTop:2, marginLeft:4, marginRight:4}}/>
                      <div stylr={{fontSize:12}}>EDIT PROFILE</div>
                    </div>
                </div> 
               </div>
                              
          </Row>
      </Container>

      <Container>
        <div className='pastdiv'>
          <Row>
            <Col xs={12} md={3}>
                <div className='myfalo'>
                    <a onClick={() => setOrder(true)} style={{cursor: 'pointer'}}>
                        {order == true ?
                        <div style={{marginLeft:10,fontWeight:550,fontSize:20}}>
                            <MdReplay size={20} color={"#7c003f"} style={{fontSize:23,marginRight:5, marginBottom:4}}/>
                            <span style={{color: '#7c003f'}}>My Orders</span>
                        </div>:
                            <div style={{marginLeft:10,fontWeight:550,fontSize:20}}>
                            <MdReplay size={20} style={{fontSize:23,marginRight:5, marginBottom:4}}/>
                            <span>My Orders</span>
                        </div>
                        }
                    </a>
                    <a onClick={() => setOrder(false)} style={{cursor: 'pointer'}}>
                        {order == false ?
                        <div style={{marginLeft:10, marginTop:10,fontWeight:550,fontSize:20}}>
                            <FaRegHeart size={20}  color={"#7c003f"} style={{marginRight:6, marginBottom:4}}/>
                            <span style={{color: '#7c003f'}}>Favourite</span>
                        </div>:
                        <div style={{marginLeft:10, marginTop:10,fontWeight:550,fontSize:20}}>
                            <FaRegHeart size={20} style={{marginRight:6, marginBottom:4}}/>
                            <span >Favourite</span>
                        </div>
                       }
                    </a>  
                  <div style={{marginLeft:10, marginTop:10,fontWeight:550,fontSize:20}}>
                    <MdLogout size={20} style={{fontSize:35,marginRight:5, marginBottom:4}}/>
                    Logout
                  </div>
                </div>
            </Col>
            <Col xs={12} md={9}>
                {order ?
                <Order />:
                <Favorite/>
                }
            </Col> 
          </Row>
        </div>
    </Container>
    
      
    </div>
  )
}


export default Accounts

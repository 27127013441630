import React from 'react'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import './Booking.css'
import {IoMdContact} from 'react-icons/io';
import frame from '../../Assets/Images/Frame.png'
import {useHistory} from 'react-router-dom';
function Booking() {
  let history = useHistory();
  return (
    <div className='book'>
      <Container>
          <Row>
              <Col xs={12} md={4}>
                  <div>
                      <p className='seldt'>Selected Date</p>
                  </div>
              </Col>
              <Col xs={12} md={4}>
                  <div className='metfd'>
                      <img className='metlog' src={frame} style={{marginLeft: 30}} />
                      <p className='metfdname'>METRO FOOD</p>
                  </div>
              </Col>
              <Col xs={12} md={4}>
                  <div className='contname'>
                      <IoMdContact size={24} style={{color:'#7c003f'}}/>
                      <p className='seldt'>Dawid Malan</p>
                  </div>
              </Col>   
          </Row>
          <Row>
          <Col>
                  <div className='bkhd'>
                    <p className='data'>Date</p>
                    <p className=''>Food</p>
                    <p className='amnt'>Amount</p>
                  </div>
                  <hr className='divhr'></hr>
              </Col>    
          </Row>
                
          <Row>
             <Col xs={3} md={3}>
                <div className='dt'>12/4/21</div>
             </Col>
             <Col xs={6} md={6}>
                <div className='bld'>
                <div className='bf'>  
                     {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'BreakFast'}
                          />
                        </div>
                      ))}
                </div>
                <div className='lun'>
                {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'Lunch'}
                          />
                        </div>
                      ))} 
                </div>
                <div className='din'>
                {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'Dinner'}
                          />
                        </div>
                      ))}  
                </div> 
                </div>
             </Col>
             <Col xs={3} md={3}>
             <div className='rs'>₹123</div>
             </Col>
            
          </Row>
          <hr className='divhr'></hr>

          <Row>
             <Col xs={3} md={3}>
                <div className='dt'>12/4/21</div>
             </Col>
             <Col xs={6} md={6}>
                <div className='bld'>
                     <div className='bf'>  
                     {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'BreakFast'}
                          />
                        </div>
                      ))}
                </div>
                <div className='lun'>
                {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'Lunch'}
                          />
                        </div>
                      ))} 
                </div>
                <div className='din'>
                {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'Dinner'}
                          />
                        </div>
                      ))}  
                </div> 
                </div>
             </Col>
             <Col xs={3} md={3}>
             <div className='rs'>₹123</div>
             </Col>
            
          </Row>
          <hr className='divhr'></hr>
          <Row>
             < div className='buttt'>
              <Button onClick={() => history.push('/Home')} className='butt' style={{backgroundColor:'#7c003f',cursor:'pointer', marginTop: 40, width:200, textAlign:'center'}}>Book Now</Button>
              </div>
          </Row>
      </Container>
    </div>
  )
}

export default Booking

import React from 'react'
import {Helmet} from "react-helmet";
import Footer from '../src/Components/Footer/Footer'
 import Router from '../src/Router'

//  global.url = 'http://localhost/erp/public/api/';
//  global.images = 'http://localhost/erp/public/'

 global.url = "https://fashionstore.nasscript.com/erp/public/api/";
  global.images = "https://fashionstore.nasscript.com/erp/public/";

function App() {
  return (
    <div>
        <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
      <Router  />
    </div>
  )
}

export default App

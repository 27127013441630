import React from 'react'
import './Header.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import { ImMobile } from 'react-icons/im';
import {IoIosArrowDown} from 'react-icons/io';
import frame from '../../Assets/Images/Frame.png'
import { FaSignInAlt, FaShoppingCart } from 'react-icons/fa';
import { IoCart } from 'react-icons/io'; 
import {RiAccountCircleFill } from 'react-icons/ri';
import {useHistory} from 'react-router-dom';


function Header() {


   let history = useHistory();

  return (
    <div className='head'>
      <Container>
          <Row>
            <Col xs={12} lg={2}>
                <div >
                  <div className='mobileapp'>
                      <ImMobile size={15} style={{marginTop: 4}}/>
                      <p className='gtp'>Get The App</p>
                  </div>
                </div>
            </Col>
            <Col xs={12} lg={3}>
                <div className='btns'>
                    <div className='btns1'>
                    <a>EN</a> 
                    <IoIosArrowDown size={15} />
                    </div>
                    <div className='btns1'>
                    <a>IND</a>
                    <IoIosArrowDown size={15}/>
                    </div>
                    
                </div>    
            </Col>
            <Col xs={12} lg={4}>
                <div className='metfd'>
                <img className='metlog' src={frame} style={{marginLeft: 30}} />
                <p className='metfdname'>FASHION STORE</p>
                </div>
            </Col>
            <Col xs={12} lg={3}>
            <div className='signitem'>
                    <div className='signitem1'> 
                     <RiAccountCircleFill size={15} style={{marginBottom: 6}}/>
                    <a  onClick={() => history.push('/Accounts')} style={{marginLeft:5, cursor:'pointer'}}>MY ACCOUNT</a>
                    </div>
                    <div className='signitem1'>
                    <FaShoppingCart size={15} style={{marginBottom: 6}}/>
                    <a style={{marginLeft:5}}>(5) ITEMS</a>
                    </div>      
                </div>  
            </Col>
          </Row>
      </Container>
    </div>
  )
}

export default Header

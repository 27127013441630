import React from 'react'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import './HotelDetails.css'
import { MdLocationPin, MdLocalOffer} from 'react-icons/md'; 
import {BsFillSuitHeartFill} from "react-icons/bs"
import fry3 from '../../Assets/Images/th.jpg'
import frame from '../../Assets/Images/Frame.png'
import { FaSignInAlt,FaStar, FaShoppingCart } from 'react-icons/fa';
import {FcClock} from 'react-icons/fc'
import {GrSquare} from 'react-icons/gr'
import {useHistory} from 'react-router-dom';

function HotelDetails() {

  let history = useHistory();

  return (
    <div className='headhot'>
       <Container>
          <Row>
            <Col xs={12} md={3} lg={4}>
                <div >
                  <div className='locapp'>
                      <MdLocationPin size={15} style={{marginTop: 4}}/>
                      
                      <p className='gtp'>Qatar</p>
                  </div>
                </div>
            </Col>
           
            <Col xs={12} md={6} lg={5}>
                <div className='metfd'>
                <img className='metlog' src={frame} style={{marginLeft: 30}} />
                <p className='metfdname'>FASHION STORE</p>
                </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
            <div className='signitem'>
                    <div className='signitem1'> 
                    <FaSignInAlt size={15} style={{marginBottom: 6}}/>
                    <a style={{marginLeft:5}}>MY ACCOUNT</a>
                    </div>
                    <div className='signitem1'>
                    <FaShoppingCart size={15} style={{marginBottom: 6}}/>
                    <a style={{marginLeft:5}}>(5) ITEMS</a>
                    </div>      
            </div>  
            </Col>
          </Row>
      </Container>
                  
            <div className='fixdet'>
              <div style={{position: 'relative'}}>
              <Container>
                    <Row>
                        <Col xs={12} md={2} lg={3}>                
                          <img className='imgfr3' src={fry3} />
                        </Col>

                        <Col xs={12} md={7} lg={6}>
                             
                                <div style={{fontSize:28, fontWeight:700, marginTop:15}}>Abayas</div>
                                <p>Doha, Qatar</p>
                                <div className='rsfcfa'>
                                    <div>
                                    <div className='fifav' style={{marginTop:10}}>
                                     <BsFillSuitHeartFill size={16} style={{marginBottom:2, marginRight:3}}/>
                                     Favourite
                                  </div>
                                    </div>
                                    <div><p style={{fontSize:20, fontWeight:600, margin:'unset'}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</p>
                                    <p>Delivery time</p>
                                    </div>
                                    <div><p style={{fontSize:20, fontWeight:600, margin:'unset'}}><FaStar style={{marginBottom:6, marginRight:5}}/>4.4</p>
                                    <p>500+ rating</p>
                                    </div>
                                </div>


                        </Col>

                        <Col xs={12} md={3} lg={3}>
                          <div className='offer'>
                                  <div className='offer-head'>OFFERS</div>
                                  <div style={{display:'flex', marginTop:10,fontWeight:500}}>
                                    <MdLocalOffer style={{marginTop:5, marginRight:3}}/>
                                    <p style={{margin:'unset'}}>30% OFF EVERY FIRST <br/> ORDER </p>
                                  </div>      
                                  <div style={{display:'flex', marginTop:10,fontWeight:500}}>
                                    <MdLocalOffer style={{marginTop:5, marginRight:3}}/>
                                    <p style={{margin:'unset'}}>30% OFF EVERY FIRST <br/> ORDER </p>
                                  </div>               
                          </div>
                        </Col> 
                    </Row>
                </Container> 
              </div>
            </div> 
 
             <div>
               <Container>
                 <Row>
                   <Col xs={12} md={2}>
                      <div style={{marginTop:20, fontWeight:600,fontSize:18}}>Recommended</div>
                      <p style={{fontWeight:600,fontSize:18}}>Items</p>
                    
                   </Col>

                   <Col xs={12} md={7}>
                      <div className='middiv'>
                          <p style={{fontSize:24, fontWeight:650}}>Recommended</p>
                          <p style={{fontSize:14, fontWeight:600, color:'#7c003f'}}>3 items</p>
                          <div className='midiv'> 
                              <div style={{display:'flex',justifyContent:'space-between'}}> 
                                  <div>
                                  <GrSquare size={20} style={{color:'#7c003f'}}/> Popular
                                  <div style={{fontSize:20, fontWeight:650, marginTop:10}}>Ets Abaya</div>
                                  <div style={{fontSize:20, fontWeight:650}}>QAR 150</div>
                                  <div style={{display:'flex'}}>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer'}}>
                                     53
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     54
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     60
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     62
                                  </div>
                                  </div>
                                  <div style={{fontSize:10, marginTop:10}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</div>
                                  </div>
                                  <img className='imfr' src={fry3} />
                                  
                              </div>
                                <div className='addv'>Add +</div>
                          </div>
                           
                          <div className='midiv'> 
                              <div style={{display:'flex',justifyContent:'space-between'}}> 
                                  <div>
                                  <GrSquare size={20} style={{color:'#7c003f'}}/> Popular
                                  <div style={{fontSize:20, fontWeight:650, marginTop:10}}>Jilbab Abaya</div>
                                  <div style={{fontSize:18, fontWeight:650}}>QAR 150</div>
                                  <div style={{display:'flex'}}>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer'}}>
                                     53
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     54
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     60
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     62
                                  </div>
                                  </div>
                                  
                                  <div style={{fontSize:10, marginTop:10}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</div>
                                  </div>
                                  <img className='imfr' src={fry3} />
                                  
                              </div>
                                <div className='addv'>Add +</div>
                          </div>

                          <div className='midiv'> 
                              <div style={{display:'flex',justifyContent:'space-between'}}> 
                                  <div>
                                  <GrSquare size={20} style={{color:'#7c003f'}}/> Popular
                                  <div style={{fontSize:20, fontWeight:650, marginTop:10}}>Jilbab Abaya</div>
                                  <div style={{fontSize:18, fontWeight:650}}>QAR 150</div>
                                  <div style={{display:'flex'}}>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer'}}>
                                     53
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     54
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     60
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     62
                                  </div>
                                  </div>
                                  
                                  <div style={{fontSize:10, marginTop:10}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</div>
                                  </div>
                                  <img className='imfr' src={fry3} />
                                  
                              </div>
                                <div className='addv'>Add +</div>
                          </div>

                          <div className='midiv'> 
                              <div style={{display:'flex',justifyContent:'space-between'}}> 
                                  <div>
                                  <GrSquare size={20} style={{color:'#7c003f'}}/> Popular
                                  <div style={{fontSize:20, fontWeight:650, marginTop:10}}>Jilbab Abaya</div>
                                  <div style={{fontSize:18, fontWeight:650}}>QAR 150</div>
                                  <div style={{display:'flex'}}>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer'}}>
                                     53
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     54
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     60
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     62
                                  </div>
                                  </div>
                                  
                                  <div style={{fontSize:10, marginTop:10}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</div>
                                  </div>
                                  <img className='imfr' src={fry3} />
                                  
                              </div>
                                <div className='addv'>Add +</div>
                          </div>
                           
                          <div className='midiv'> 
                              <div style={{display:'flex',justifyContent:'space-between'}}> 
                                  <div>
                                  <GrSquare size={20} style={{color:'#7c003f'}}/> Popular
                                  <div style={{fontSize:20, fontWeight:650, marginTop:10}}>Jilbab Abaya</div>
                                  <div style={{fontSize:18, fontWeight:650}}>QAR 150</div>
                                  <div style={{display:'flex'}}>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer'}}>
                                     53
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     54
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     60
                                  </div>
                                  <div className='fifa' style={{marginTop:10, fontWeight:'bold', cursor: 'pointer', marginLeft:5}}>
                                     62
                                  </div>
                                  </div>
                                  
                                  <div style={{fontSize:10, marginTop:10}}><FcClock style={{marginBottom:3, marginRight:5}}/>20-30</div>
                                  </div>
                                  <img className='imfr' src={fry3} />
                                  
                              </div>
                                <div className='addv'>Add +</div>
                          </div>

                      </div>
                      
                   </Col>

                   <Col xs={12} md={3}>
                   <div className='smdivision'>
                   <div className='cart' style={{fontSize:20, fontWeight:600}}>Cart</div>
                   <div className='onitm' style={{fontSize:11, color:'blue', fontWeight:600}}>(one item)</div>
                   <hr></hr>

                   <div className='imgminplus'>
                       <img className='imfry' style={{borderRadius:30, width:45}}  src={fry3} />
                       <div className='cfr' style={{fontSize:14, marginLeft:10, marginTop:7}} >Jilbab abaya</div>
                       <div className='minplus'>
                       <div className='mipl'>
                       <div className='mp' style={{color:'blue', fontSize:16, fontWeight:'bold'}}>-</div>
                       <div className='m2p'  style={{fontSize:14, fontWeight:'bold', marginTop:2}}>2</div>
                       <div className='mp'  style={{color:'blue', fontSize:16, fontWeight:'bold'}}>+</div>
                       </div>
                       </div>
                       <div style={{marginLeft:10, marginTop:7}}>₹160</div>
                   </div>
                   <hr></hr>
                   <div style={{backgroundColor:'#f5f5f5',display:'flex', justifyContent:'space-between', padding:10}}>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>Sub Total</p>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>300</p>
                   </div>
                   
                    <div className='buttt'>
                    <Button onClick={() => history.push('/Checkout')} className='butt' style={{backgroundColor:'#7c003f',cursor:'pointer', marginTop: 30, width:200, textAlign:'center'}}>CHECK OUT</Button>
                    </div>
         

                </div>
                   </Col>
                 </Row>
               </Container>
             </div>




    </div>
    
  )
}

export default HotelDetails

import React, {useEffect,useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Intro.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import side2 from '../../Assets/Images/un.jpg'
import { MdOutlineMyLocation } from 'react-icons/md'
import { IoIosArrowDown } from 'react-icons/io'
import { FiDivide, FiSearch  } from 'react-icons/fi'
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import {BsArrowReturnRight} from "react-icons/bs"
import logo from '../../Assets/Images/Frame.png'
import side1 from '../../Assets/Images/abay.jpg'
import fry3 from '../../Assets/Images/abay2.jpg'
import samu from '../../Assets/Images/abay1.jpg'
import delvry from '../../Assets/Images/abb.jpg'  
import fry5 from '../../Assets/Images/abb2.jpg'   
import video from '../../Assets/Images/vd.mp4'     
import VideoPlayer from 'react-video-js-player'
import pic1 from '../../Assets/Images/1.svg'
import pic2 from '../../Assets/Images/2.svg'
import pic3 from '../../Assets/Images/3.svg'
import pic4 from '../../Assets/Images/4.svg'
import pic5 from '../../Assets/Images/5.svg'
import {useHistory} from 'react-router-dom'
function Intro() {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [location, setLocation] =useState("")
  const [banner1, setBanner1] = useState('');
  const [banner2, setBanner2] = useState('');
  const [banner3, setBanner3] = useState('');
  const [banner4, setBanner4] = useState('');
  const [banner5, setBanner5] = useState('');
   
 const videoSrc=video;
 let history = useHistory();

   
  
  return (
    <div>
      <Container>
        <Row> 
          <Col xs={12} md={8} lg={8}>
            <div className='header'>
              <div className='metlogometro'>
              <img className='metlogo' src={logo} />
              <p className='metro'></p>
              </div>
              <div className='losi'>
              <a onClick={() => history.push('/Home')} style={{cursor: 'pointer'}} className='btn1'>LOGIN</a> 
              <a onClick={() => history.push('/Signup')} style={{cursor: 'pointer'}} className='btn2'>SIGN UP</a>
              </div>
            </div> 
            <div className='para'>
              <h3>TRY NOW !!</h3>
              <p className="para1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                  and scrambled it to make a type specimen book. It has survived not only five centuries, but also the 
                  leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with 
                  the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing 
                  software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div> 
            
              {/* <div style={{position: 'relative'}}>
                  <div className='searchdiv'>
                  <div className='location'>   
                <div className='searchbx'>
                    <Form.Control className='searchbox'
                    type="text"
                    id="inputPassword5"
                    placeholder='Select Your Location'
                    aria-describedby="passwordHelpBlock"
                    onChange={handleSearch} value={wordEntered}
                    />
                </div>     
                <div className='searchicon'>
                    <FiSearch size={20} style={{marginTop: 8, color:'white'}}/>
                </div>  
            </div> 
                  </div>
                  {filteredData.length == 0 ? null:
                      <div className="dataResult">
                      {filteredData.map((value, key) => {
                          return (
                          <a onClick={() => locationSearch(value.location_name)} className="searchItem" target="_blank">
                              <div style={{marginTop:10, display: 'flex'}}>
                                  <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                  <p style={{fontSize: 12, marginLeft: 10}}>{value.location_name}</p>
                              </div>
                              <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                          </a>
                          );
                      })}
                      </div>
                  }
              </div>    */}
          </Col>
          <Col xs={12} md={4} lg={4}>
            <img className='imgside2' src={side2} />
          </Col>
        </Row>
      
      {/* <div className='pros'>
        <div style={{textAlign:'center', fontSize:35, fontWeight:600, marginBottom:20}}>Our Process</div>
        <Container>
          <Row>
            <Col xs={12} md={3} lg={1}> </Col>
            <Col xs={12} md={3} lg={2}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <img className='picc' src={pic1} />
              </div>
              <p style={{fontSize: 13, fontWeight: 600, textAlign: 'center', color: '#bebebe'}}>Order Through Metro Food
              Online Ordering With Any Device</p>
            </Col>
            <Col xs={12} md={3} lg={2}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <img className='picc' src={pic2} />
              </div>
            
            <p style={{fontSize: 13, fontWeight: 600, textAlign: 'center', color: '#bebebe'}}>Restaurant Prepares Order</p>
            </Col>
            <Col xs={12} md={3} lg={2}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <img className='picc' src={pic3} />
               </div>
            
            <p style={{fontSize: 13, fontWeight: 600, textAlign: 'center', color: '#bebebe'}}>Customer Arrives To Restaurant
                    Curbside Pickup</p>
            </Col>
            <Col xs={12} md={3} lg={2}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <img className='picc' src={pic4} />
               </div>
           
            <p style={{fontSize: 13, fontWeight: 600, textAlign: 'center', color: '#bebebe'}}>Customer Marks
                    I m Here  Online</p>
            </Col>
            <Col xs={12} md={3} lg={2}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <img className='picc' src={pic5} />
               </div>
            
            <p style={{fontSize: 13, fontWeight: 600, textAlign: 'center', color: '#bebebe'}}>Restaurant Delivers Food To Vehicle</p>
            </Col>
            <Col xs={12} md={3} lg={1}> </Col>
          </Row>
        </Container>
      </div> */}


       <Row>
         <Col >  
         <VideoPlayer className='video' src={videoSrc} />   
         </Col>
       </Row>
     
        <Row> 
          <Col  xs={12} md={6} lg={6}> 
         
          <img className='imgside1' src={side1} />
          
           <img className='imgfry3' src={fry3} />
           
           <img className='imgsamu' src={samu} /> 
          </Col>
          <Col  xs={12} md={6} lg={6}>
          
            <img className='imgdelvry' src={delvry} />
            
            <img className='imgfry5' src={fry5} />
          </Col> 
        </Row>
      </Container>
    </div>
    
  )
}

export default Intro

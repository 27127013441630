import React, {useEffect, useState} from 'react'
import './Login.css'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import frame from '../../Assets/Images/Frame.png'
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import { useHistory, useParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Loader from '../../Components/Loader/Loader';
import { id } from 'date-fns/locale';

const Alert = React.forwardRef(function Alert(props, ref) {
return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {

  let history = useHistory();
  const vertical = "top"
  const horizontal = "center"
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  

  const [usernameErr, setUsernameErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrormessage] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
}
const formValidation = (e) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const re = /^[0-9\b]+$/;
  const usernameErr = {};
  const passwordErr = {};
  let isValid = true;

  if(username.trim().length == 0){
      usernameErr.namerequired = 'Name is required'
  }
  if(password.trim().length == 0){
      passwordErr.passwordRequired = 'Password is required';
      isValid = false;
  }
  if(password.trim().length < 6){
      passwordErr.passwordRequired = 'Password is too short (Min 6 Letters)';
      isValid = false;
  }
  if(password.trim().length > 20){
      passwordErr.passwordRequired = 'Password is too long (Max 20 Letters)';
      isValid = false;
  }
  if(username.trim().length != 0 && password.trim().length !=0)  {
      getData()
  }
  setUsernameErr(usernameErr);
  setPasswordErr(passwordErr);
  return isValid  
}
const handleClose = (event, reason) => {
  if(reason === 'clickaway'){
      return;
  }
  setOpen(false);
}

const getData = () => {
  setLoader(true)
  var data = {
      "emailid" : username , 
      "password" : password,
     
  }
  console.log(data, "error")
  fetch(global.url + "auth/Metrologin", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129'     
    },
    body: JSON.stringify(data)
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
    console.log("response signup", responseJson)  
    setLoader(false)
    if(responseJson.error == false){
        var data = responseJson.data
        var data1 = data.user_data
        console.log("data", data1)
        localStorage.setItem("isAuthenticatedmetro", true);
        localStorage.setItem("tokenMetro", JSON.stringify(data.api_token));
        localStorage.setItem("usernameMetro", JSON.stringify(data1.user_name));
        history.push('/home');
    }else{
      setOpen(true)
      setErrormessage(responseJson.message)
    }
  })
  .catch((error)=>{
      console.log(error)
  })
}
  return (
    <div>
      <Container>
        <Row>
          
          <Col xs={12}>
            <div className='loginbox'>
            <div className='box-inside'>
            <div style={{textAlign:'center'}}>
            <img className='imgframe' src={frame} style={{width:120, height:80, marginLeft:20}} />
            </div>
            <div style={{fontSize:30, fontWeight:700, color:'#7c003f', textAlign:'center'}}>Login</div>
            
            <div style={{marginTop:20}}>
            <Form>
              <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                      type="text"
                      className='shadow12' 
                      value={username}
                      onChange={(e) => {setUsername(e.target.value)}} 
                      placeholder='Enter Your Email' />
              </Form.Group>
              {Object.keys(usernameErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{usernameErr[key]}</div>
                              })}
           </Form>
            </div>
            
            <div style={{marginTop:20}}>
            <Form>
              <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label>Password</Form.Label>
                  <Form.Control 
                      type="password"
                      className='shadow12' 
                      value={password}
                      onChange={(e) => {setPassword(e.target.value)}} 
                      placeholder='Enter Your Password' />
              </Form.Group>
              {Object.keys(passwordErr).map((key) => {
                                  return <div style={{color: 'red', fontSize: 12}}>{passwordErr[key]}</div>
                              })}
           </Form>
            </div>
            
            {loader ?
            <Loader />:
              <Button onClick={onSubmit}className='losg' style={{marginTop:20,cursor: 'pointer', width:'100%', backgroundColor:'#7c003f'}}>Login</Button>
            }
               <div style={{marginTop:20, textAlign:'center'}}>Don't have an account? <a onClick={() => history.push('/Signup')} style={{color:'#7c003f', cursor: 'pointer'}}>Signup</a></div>
                
            </div>
            </div>
            </Col>
           
        </Row>
      </Container>      
      <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>                 
    </div>
  )
}

export default Login

import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import '../../App.css';

const DashboardLayout = ({ children, ...rest }) => {
    const [toggle,  setToggle] = useState(false)
    
    function setData(childData) {
        setToggle(childData)
      }

      
  return (
    <div className="HomeParentDiv">
        
        <div className='div-margin' style={{marginBottom: 50}}>{children}</div>
        <Footer />
    </div>
  );
};

const CommonLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
       
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout> 
      )}
    />
  );
};

export default CommonLayoutRoute;
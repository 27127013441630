import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, Modal, Button} from "react-bootstrap"
import food from '../../Assets/Images/ab.jpg'
import food1 from '../../Assets/Images/th.jpg'
import food2 from '../../Assets/Images/th (4).jpg'
import food3 from '../../Assets/Images/th (8).jpg'
import food4 from '../../Assets/Images/th (5).jpg'
import food5 from '../../Assets/Images/th (7).jpg'
import food6 from '../../Assets/Images/th (3).jpg'
import food7 from '../../Assets/Images/th (2).jpg'
import food8 from '../../Assets/Images/th (9).jpg'
import meta from '../../Assets/Images/aba.jpg'
import './Product.css'
import {FcLike} from "react-icons/fc"
import {BsFillStarFill } from "react-icons/bs"
import {BsArrowReturnRight} from "react-icons/bs"
import { MdLocationOn } from 'react-icons/md'
import {FiSearch } from 'react-icons/fi'
import {useHistory} from 'react-router-dom';

function Product() {

    const [popup, setPopup] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [location, setLocation] =useState("")
    const [locationName, setLocationName] = useState("Location")
    const [restaurants, setRestaurents] = useState([])
    const [count, setCount] = useState("")
    let history = useHistory();

    

    return (
    <div>
        {/* <Row>
            <Col>
            <div className='locationsearch'>
                <div className='mdltkl'>
                    <MdLocationOn size={20} style={{marginTop:2}} />
                    <p onClick={()=>setPopup(true)} style={{fontWeight:'bold'}}>fhhdjd</p>
                </div>
                <div className='locsrch'>
                    <Form.Control className='sarch'
                    type="text"
                    id="inputPassword5"
                    placeholder='Search by restaurants or dishes'
                    aria-describedby="passwordHelpBlock"
                    />      
                    <FiSearch className='sercicon' size={16} style={{marginTop: 8,marginRight:6, color:'#7c003f'}}/>    
                </div>
            </div>      
            </Col>
        </Row> */}
        
       <Row>
           <Col>
             <div className='items'>
                <div className='itm1'>Abayas(170)</div> 
                <div className='itm'>shwall(23)</div>
                <div className='itm'>Hijabs(41)</div> 
                    
             </div>
           </Col>
       </Row>
         
       <Row>
           <Col>
             <div className='restrnd'>
                <div>{count} Shops</div>
                <div>Filters</div>      
             </div>
           </Col>
       </Row>

       <Row> 
         <Col xs={12} md={6} lg={3} > 
                    <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
                        <img className='fdimg' src={food} style={{width:'100%'}} />
                        <div className='descrpt'>
                            <div className='kalfc'> 
                            <p>Abayas</p>
                            <FcLike />
                            </div>
                            <div className='strvewrs'>
                                <div className='strvew'>
                                    <div>
                                        <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                    </div>
                                    <div className='review'>
                                        32 Reviews
                                    </div>      
                                </div> 
                               
                            </div>
                            <div className='arbindmin'>
                                
                                    <div className='min'>20 Min</div>
                            </div>
                        </div>   
                    </div>
                </Col>
            
            
                <Col xs={12} md={6} lg={3} > 
                    <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
                        <img className='fdimg' src={food1} style={{width:'100%'}} />
                        <div className='descrpt'>
                            <div className='kalfc'> 
                            <p>Pardha mall</p>
                            <FcLike />
                            </div>
                            <div className='strvewrs'>
                                <div className='strvew'>
                                    <div>
                                        <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                    </div>
                                    <div className='review'>
                                        32 Reviews
                                    </div>      
                                </div> 
                            
                            </div>
                            <div className='arbindmin'>
                                
                                    <div className='min'>20 Min</div>
                            </div>
                        </div>   
                    </div>
                </Col>
            
            
                <Col xs={12} md={6} lg={3} > 
                    <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
                        <img className='fdimg' src={food2} style={{width:'100%'}} />
                        <div className='descrpt'>
                            <div className='kalfc'> 
                            <p>Arafa</p>
                            <FcLike />
                            </div>
                            <div className='strvewrs'>
                                <div className='strvew'>
                                    <div>
                                        <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                    </div>
                                    <div className='review'>
                                        32 Reviews
                                    </div>      
                                </div> 
                              
                            </div>
                            <div className='arbindmin'>
                                
                                    <div className='min'>20 Min</div>
                            </div>
                        </div>   
                    </div>
                </Col>

                <Col xs={12} md={6} lg={3} > 
                    <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
                        <img className='fdimg' src={food1} style={{width:'100%'}} />
                        <div className='descrpt'>
                            <div className='kalfc'> 
                            <p>Pardha mall</p>
                            <FcLike />
                            </div>
                            <div className='strvewrs'>
                                <div className='strvew'>
                                    <div>
                                        <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                        <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                                    </div>
                                    <div className='review'>
                                        32 Reviews
                                    </div>      
                                </div> 
                              
                            </div>
                            <div className='arbindmin'>
                                
                                    <div className='min'>20 Min</div>
                            </div>
                        </div>   
                    </div>
                </Col>
        </Row>

      <Row> 
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food3} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Bismi</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                      
                   </div>
                   <div className='arbindmin'>
                      
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
   
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food4} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Dubai abayas</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                      
                   </div>
                   <div className='arbindmin'>
                       
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food3} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Bismi</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                     
                   </div>
                   <div className='arbindmin'>
                       
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
   
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food5} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Mall of Abayas</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                      
                   </div>
                   <div className='arbindmin'>
                       
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
</Row>


<Row> 
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food6} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Abayas Botique</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                     
                   </div>
                   <div className='arbindmin'>
                      
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
   
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food7} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Alham</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                      
                   </div>
                   <div className='arbindmin'>
                      
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
   
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food8} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Riyazz</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                    
                   </div>
                   <div className='arbindmin'>
                      
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
       <Col xs={12} md={6} lg={3} > 
           <div onClick={() => history.push('/HotelDetails')} className='imgfst' style={{cursor:'pointer'}}>
               <img className='fdimg' src={food7} style={{width:'100%'}} />
               <div className='descrpt'>
                   <div className='kalfc'> 
                   <p>Alham</p>
                   <FcLike />
                   </div>
                   <div className='strvewrs'>
                       <div className='strvew'>
                           <div>
                               <BsFillStarFill size={12} style={{marginBottom:8,color:'yellow'}} />
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1,color:'yellow'}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                               <BsFillStarFill size={12} style={{marginBottom:8, marginLeft:1}}/>
                           </div>
                           <div className='review'>
                               32 Reviews
                           </div>      
                       </div> 
                    
                   </div>
                   <div className='arbindmin'>
                       
                           <div className='min'>20 Min</div>
                   </div>
               </div>   
           </div>
       </Col>
   
</Row>



        <div style={{marginBottom:30}}>
        <Row>
            <Col xs={12} md={6}>
                  <img className='meta' src={meta} />   
            </Col>
            <Col xs={12} md={6}>
                  <img className='meta' src={meta} />  
            </Col>    
        </Row>
        </div>

        <Modal show={popup} onHide={() => setPopup(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          
           <div style={{position: 'relative'}}>
                  <div className='searchdiv'>
                  <div className='location'>   
                <div className='searchbx'>
                    <Form.Control className='searchbox'
                    type="text"
                    id="inputPassword5"
                    placeholder='Select Your Location'
                    aria-describedby="passwordHelpBlock"
                    value="yyuh"
                    />
                </div>     
                <div className='searchicon'>
                    <FiSearch size={20} style={{marginTop: 8, color:'white'}}/>
                </div>  
            </div> 
                  </div>
                  {filteredData.length == 0 ? null:
                      <div className="dataResult">
                    
                        
                          <a  className="searchItem" target="_blank">
                              <div style={{marginTop:10, display: 'flex'}}>
                                  <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                  <p style={{fontSize: 12, marginLeft: 10}}>ytyetrsrwe</p>
                              </div>
                              <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                          </a>
                       
                      </div>
                  }
              </div> 
            
        </Modal.Body>
      </Modal>


    </div>
  )
}

export default Product

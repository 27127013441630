import React, {useState} from 'react'
import './Order.css'
import fry3 from '../../Assets/Images/th (2).jpg'
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap"

function Order() {
    const [show, setShow] = useState(false);
  return (
    <div>
       
        <div className='pastdiv'>
          <Row>
           
            <Col xs={12}>
                <div style={{marginLeft:10,fontWeight:600,fontSize:25,color: '#7c003f', marginTop:30}}>
                  Past Orders
                </div>

                <div className='picdiv'>
                  <Row>
                      <Col xs={12} md={3}>
                        <img src={fry3} style={{width:170,height:120}}/>
                      </Col>
                      <Col xs={12} md={5}>
                        <div style={{fontSize:18, fontWeight:'bold'}}>Jilbab Abaya</div>
                        <div style={{fontSize:12}}>Doha</div>
                        <div style={{fontSize:14}}>ORDER #22835410852 | Wed, Oct 17, 2018, 12:40 PM</div>
                        <div onClick={()=>setShow(true)} style={{color:'#7c003f', marginTop:30, fontWeight:600}}>VIEW DETAILS</div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{fontSize:14}}>Delivered  on Wed, oct 17,2018, 01:13PM</div>
                      </Col>
                  </Row>
                      <hr></hr>
                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <p>Abayas x5</p>
                        <p>Total Paid:QAR 250</p>
                      </div>
                      <Button className='butreord'>REORDER</Button>
                </div>

                <div style={{marginLeft:10,fontWeight:600,fontSize:25,color: '#7c003f', marginTop:30}}>
                  Current Orders
                </div>

                <div className='picdiv'>
                  <Row>
                      <Col xs={12} md={3}>
                        <img src={fry3} style={{width:170,height:120}}/>
                      </Col>
                      <Col xs={12} md={9}>
                        <div style={{fontSize:18, fontWeight:'bold'}}>Jilbab Abaya</div>
                        <div style={{fontSize:12}}>Doha</div>
                        <div style={{fontSize:14}}>ORDER #22835410852 | Wed, Oct 17, 2018, 12:40 PM</div>
                      </Col>
                      
                  </Row>
                      <hr></hr>
                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div>
                        <div>Abayas x5</div>
                        <div>Abayas x5</div>
                        <div>Abayas x5</div>
                        </div>
                        
                        <p>Total:QAR 150</p>
                      </div>
                     
                </div>
                  
            </Col>
          </Row>
        </div>
      

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
        <Modal.Title>Order #22835410852</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           <p style={{fontSize:12, color: 'rgb(155 155 155)'}}>Delivered  on Wed, oct 17,2018, 01:13PM</p>
           <p style={{fontSize:12, color: 'rgb(155 155 155)'}}>1 ITEM</p>
           <div style={{display:'flex', justifyContent:'space-between'}}>
                <p>Abayas x5</p>
                <p>QAR 250</p>
            </div>
            <div className='hrhr'></div>
            <div style={{display:'flex', justifyContent:'space-between', fontSize:12, color: 'rgb(155 155 155)'}}>
                <p>Order Packing Charges</p>
                <p>QAR 250</p>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', fontSize:12, color: 'rgb(155 155 155)'}}>
                <p>Delivery Partner Fee </p>
                <p>₹ 250</p>
            </div>
            <hr></hr>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <p>Total</p>
                <p>QAR 250</p>
            </div>
            <p style={{fontSize:12, color: 'rgb(155 155 155)'}}>Pay Via Cash</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default Order

import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Intro from './Screens/Intro/Intro';
import Home from './Screens/Home/Home';
import Booking from './Screens/Booking/Booking'
import Checkout from './Screens/Checkout/Checkout';
import HotelDetails from './Screens/HotelDetails/HotelDetails';
import Accounts from './Screens/Accounts/Accounts';
import Messbooking from './Screens/Mess-booking/Messbooking';
import Login from './Screens/Login/Login';
import Signup from './Screens/Signup/Signup'; 
import CommonLayout from './Components/Layout/CommenLayout';

function rout() {
  return (
    <div>
      <Router>
          <Switch>
              <CommonLayout exact path='/' component={Intro} />
              <CommonLayout exact path='/home' component={Home} />
              <CommonLayout exact path='/Booking' component={Booking} />
              <CommonLayout exact path='/Checkout' component={Checkout} />
              <CommonLayout exact path='/HotelDetails' component={HotelDetails} />
              <CommonLayout exact path='/Accounts' component={Accounts} />
              <CommonLayout exact path='/Messbooking' component={Messbooking} />
              <Login exact path='/Login' component={Login} />
              <Signup exact path='/Signup' component={Signup} />
          </Switch>
      </Router>
    </div>
  )
}

export default rout;


import React, {useState} from 'react'
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap"
import './Checkout.css'
import frame from '../../Assets/Images/Frame.png'
import {VscAccount} from 'react-icons/vsc'
import {TiTick} from 'react-icons/ti'
import {ImPlus} from 'react-icons/im'
import {FaRegAddressCard} from 'react-icons/fa'
import {MdOutlinePayment ,MdDelete} from 'react-icons/md'
import fry3 from '../../Assets/Images/ab.jpg'
import TextField from '@mui/material/TextField';
function Checkout() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <Container>
        <div className='checkout'>
          <Row>
                <Col xs={12} md={4}>
                    <div>
                        <p className='out'>CHECKOUT</p>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className='metfd'>
                        <img className='metlog' src={frame} style={{marginLeft: 30}} />
                        <p className='metfdname'>FASHION STORE</p>
                    </div>
                </Col>        
          </Row>
        </div>
       

        <Row>
              <Col xs={12} md={8}>
                <div className='division'>
                 
                     <div className='vacct'>
                        <VscAccount size={20} style={{marginTop:10, color:'blue', marginRight:6}}/>
                        <p className='acc'>Account</p>
                        <div className='tick'>
                        <TiTick size={20} style={{color:'white', marginBottom:'6'}}/>
                        </div>
                     </div>
                 
                    
                        <Row>
                            <Col xs={12} md={6}> 
                              <div className='but'>
                                <Button className='butt' style={{backgroundColor:'blue', marginTop: 15, width:200, height:50 }}>
                                    <p className='btnm'>Have A Account</p>
                                  Login
                                </Button>
                              </div>
                                
                            </Col>
                            <Col xs={12} md={6}>
                              <Button className='butt' style={{backgroundColor:'green', marginTop: 15, width:200, height:50}}>
                                  <p className='btnm'>Don't Have A Account</p>
                                  Sign Up
                              </Button>
                            </Col>
                        </Row> 

                        <div className='vadel'>
                            <FaRegAddressCard size={20} style={{marginTop:10, color:'blue', marginRight:6}}/>
                            <p className='acc'>Delivery Address</p>
                            <div className='tick'>
                            <TiTick size={20} style={{color:'white', marginBottom:'6'}}/>
                            </div>
                        </div>

                        <div className="implus" style={{display:'flex'}}>
                        <ImPlus size={12} style={{marginTop:7, marginRight:4}} />
                        <div onClick={()=>setShow(true)} style={{fontSize:17,fontWeight:500}}>Add new Address</div>
                        </div>
                      

                        <Row>
                          <Col xs={12} md={4}> 
                            <div className='smdiv'>
                                <div className='hommin'>Home</div>
                                <p className='addpara'>Wadi Aba Seleel,
                                Doha, 
                                Qatar </p>
                                <div className='btddiv'>
                                <MdDelete size={22} style={{marginTop:7, color:'#e90f0f' }}/>
                                <Button className='btd' style={{backgroundColor:'green', marginTop:6, height:25,  fontSize:12, paddingTop:2}}>
                                Deliver Here</Button>
                                </div>  
                            </div>
                          </Col>

                          <Col xs={12} md={4}> 
                            <div className='smdiv'>
                                <div className='hommin'>Home</div>
                                <p className='addpara'>Wadi Aba Seleel,
                                Doha, 
                                Qatar </p>
                                <div className='btddiv'>
                                <MdDelete size={22} style={{marginTop:7, color:'#e90f0f' }}/>
                                <Button className='btd' style={{backgroundColor:'green', marginTop:6, height:25,  fontSize:12, paddingTop:2}}>
                                Deliver Here</Button>
                                </div>  
                            </div>
                          </Col>

                          <Col xs={12} md={4}> 
                            <div className='smdiv'>
                                <div className='hommin'>Home</div>
                                <p className='addpara'>Wadi Aba Seleel,
                                Doha, 
                                Qatar </p>
                                <div className='btddiv'>
                                <MdDelete size={22} style={{marginTop:7, color:'#e90f0f' }}/>
                                <Button className='btd' style={{backgroundColor:'green', marginTop:6, height:25,  fontSize:12, paddingTop:2}}>
                                Deliver Here</Button>
                                </div>  
                            </div>
                          </Col>
                            
                          <Row>
                              <Col xs={12} md={6}>
                                <div className='chdiv'>
                                    <div className='homin'>Home</div>
                                    <p className='kptp'>KWadi Aba Seleel,
                                    Doha
                                   Qatar </p>
                                    <div className='homin'>32 Min</div>
                                </div>
                              </Col> 
                              <Col xs={12} md={6}>
                                    <div onClick={()=>setShow(true)} className='chan'>Change</div>
                              </Col>
                          </Row>  
                            
                           <div className='vadel'>
                              <MdOutlinePayment size={20} style={{marginTop:10, color:'blue', marginRight:6}}/>
                              <p className='acc'>Choose Payment Method</p>
                              <div className='tick'>
                              <TiTick size={20} style={{color:'white', marginBottom:'6'}}/>
                              </div>
                           </div>
                        </Row>
                            <div className='codv'>
                              <div className='coddiv'>
                                <p className='cod'>COD</p>
                              </div>
                            </div>
                         

                         <div className='paydiv'>
                            <Button className='pay' style={{backgroundColor:'#7c003f', marginTop: 15, width:120, height:40}}>
                              <p className='payp' style={{fontSize:18, fontWeight:800}}>PAY</p>
                            </Button>
                         </div>               

                  </div>     
              </Col>


              <Col xs={12} md={4}>
                <div className='smdivision'>
                   <div className='cart' style={{fontSize:20, fontWeight:600}}>Cart</div>
                   <div className='onitm' style={{fontSize:11, color:'blue', fontWeight:600}}>(one item)</div>
                   <hr></hr>

                   <div className='imgminplus'>
                       <img className='imfry' style={{borderRadius:30, width:45}}  src={fry3} />
                       <div className='cfr' style={{fontSize:14, marginLeft:10, marginTop:7}} >Jilbab Abaya</div>
                       <div className='minplus'>
                       <div className='mipl'>
                       <div className='mp' style={{color:'blue', fontSize:16, fontWeight:'bold'}}>-</div>
                       <div className='m2p'  style={{fontSize:14, fontWeight:'bold', marginTop:2}}>2</div>
                       <div className='mp'  style={{color:'blue', fontSize:16, fontWeight:'bold'}}>+</div>
                       </div>
                       </div>
                       <div style={{marginLeft:10, marginTop:7}}>QAR 160</div>
                   </div>
                   <hr></hr>
                   
                   <div className='covid'>
                     <div>
                     {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check 
                            type={type}
                            id={`default-${type}`}
                            label={'No-contact delivery'}
                          />
                        </div>
                      ))}
                    </div>
                   
                     <p style={{fontSize:13}}>
                        No-contact or contactless delivery simply means that a food order
                        is left at the customer's doorstep, lobby, or another safe place, 
                        rather than ringing the bell or knocking on the door and having 
                        the food change hands directly
                     </p>
                   </div>

                   <div className='srchcpn'>
                   <Form.Control className='coupon'
                    type="text"
                    id="inputPassword5"
                    placeholder='Apply Coupon Code'
                    aria-describedby="passwordHelpBlock"
                    />     
                   </div>
                   <div className='apply'>
                      <Button className='applybtn' style={{backgroundColor:'#7c003f', width:80, height:40}}>
                        <p  style={{fontSize:18, fontWeight:600}}>Apply</p>
                      </Button>
                   </div> 
                  
                   <div style={{fontWeight:600, fontSize:18, marginTop:6}}>Bill Details</div>
                   <div style={{display:'flex', justifyContent:'space-between', marginTop:20}}>
                     <p>item total</p>
                     <p>QAR 160</p>
                   </div>

                   <div style={{display:'flex', justifyContent:'space-between'}}>
                     <p>item total</p>
                     <p>QAR 160</p>
                   </div>

                   <div style={{display:'flex', justifyContent:'space-between'}}>
                     <p>item total</p>
                     <p>QAR 160</p>
                   </div>
                   <div style={{backgroundColor:'#f5f5f5',display:'flex', justifyContent:'space-between', padding:10}}>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>Total</p>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>300</p>
                   </div>

                </div>
              </Col>
          </Row>             
      </Container>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          <div>
           <Row>
              <Col xs={12} md={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  
                />
                </Col>
                <Col xs={12} md={6}>
                <TextField className='pop'
                  required
                  id="outlined-required"
                  label="Email Address"
                  
                />
                </Col>
           </Row>
           </div>
           <div style={{marginTop:20}}>
           <Row>
              <Col xs={12} md={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Phone Number"
                 
                />
                </Col>
                <Col xs={12} md={6}>
                <TextField className='pop'
                  required
                  id="outlined-required"
                  label="Set Location"
                 
                />
                </Col>
           </Row>
           </div>
           <div style={{marginTop:20}}>
           <Row>
              <Col xs={12} md={4}>
                <TextField
                  required
                  id="outlined-required"
                  label="Building Number"
                 
                />
                </Col>
                <Col xs={12} md={4}>
                <TextField className='pop'
                  required
                  id="outlined-required"
                  label="Zone"
                 
                />
                </Col>
                <Col xs={12} md={4}>
                <TextField className='pop'
                  required
                  id="outlined-required"
                  label="Street Number"
                  
                />
                </Col>
                </Row>
                </div>
               
               <div style={{marginTop:20}}>
              <Row>
                <Col xs={12} md={12}>
                <TextField style={{width:'100%'}}
                  required
                  id="outlined-required"
                  label="Additional Direction"
                  
                />
                </Col>
              </Row>         
            </div>
           
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      
           <div className='checkfoot'>
            <div style={{fontSize:22, fontWeight:700 }}><u>ADDRESS</u></div> 
            
              <div style={{fontSize:12, fontWeight:700, marginTop:10}}>Alwa hi haka,</div>
              <div style={{fontSize:12, fontWeight:700 }}>Doha, Qatar</div>
             
           </div>

    </div>
    
  )
}

export default Checkout

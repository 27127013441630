import React from 'react'
import {Container, Row, Col, Form} from "react-bootstrap"
import Banner from '../../Components/Banner/Banner.js'
import Product from '../../Components/Product/Product.js'
import Header from '../../Components/Header/Header'
import Section from '../../Components/Section/Section'
import './Home.css'


function Home() {

  
  return (
    <div>
      <Header />
      <div>
      <Container>
      <Row>
        <Col xs={12} md={9} lg={12}>
          <Banner />
          <Product/>
        </Col>
        
      </Row>
    </Container>
      </div>
    </div>
  )
}

export default Home

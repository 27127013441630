import React from 'react'
import './Messbooking.css'
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import {IoMdContact} from 'react-icons/io';
import frame from '../../Assets/Images/Frame.png';
import FormControl from '@mui/material/FormControl';
import {useHistory} from 'react-router-dom';


function Messbooking() {
  let history = useHistory();
  const [value1, setValue1] = React.useState(new Date());
  const [value2, setValue2] = React.useState(new Date());

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();
    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);

  return (
    <div  style={{marginTop:25, marginBottom:40}}>
      <Container>
        <Row>
        <Col xs={12} md={4}>
                  <div>
                      <p className='seldt'>Mess Food Booking</p>
                  </div>
              </Col>
              <Col xs={12} md={5}>
                  <div className='metfd'>
                      <img className='metlog' src={frame} style={{marginLeft: 30}} />
                      <p className='metfdname'>METRO FOOD</p>
                  </div>
              </Col>
              <Col xs={12} md={3}>
                  <div className='contname'>
                      <IoMdContact size={24} style={{color:'#7c003f'}}/>
                      <p className='seldt'>Dawid Malan</p>
                  </div>
              </Col>
        </Row>
        <div style={{textAlign:'center', fontSize:23, fontWeight:630}}>Food List</div>
        <Row>
          <div style={{marginTop:30, overflowX: 'auto'}}>
              <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Date</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Sun</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Mon</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Tue</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Wed</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">The</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Fri</th>
                      <th style={{color: '#fff', backgroundColor: '#7c003f'}} scope="col">Sat</th>
                    </tr>
                    <tr>
                      <th  style={{color: '#fff', backgroundColor: '#7c003f'}} scope="row">Break fast</th>
                      <td>Dosha</td>
                      <td>Idali</td>
                      <td>Puttu</td>
                      <td>Appam</td>
                      <td>Chappathi</td>
                      <td>Idiyappam</td>
                      <td>Dosha</td>
                    </tr>
                    <tr>
                      <th  style={{color: '#fff', backgroundColor: '#7c003f'}} scope="row">Lunch</th>
                      <td>Rice</td>
                      <td>Ghee Rice</td>
                      <td>Mandi</td>
                      <td>Chicken Biriyani</td>
                      <td>Kabsa</td>
                      <td>Muttun Biriyni</td>
                      <td>Beef Biriyani</td>
                    </tr>
                    <tr>
                      <th  style={{color: '#fff', backgroundColor: '#7c003f'}} scope="row">Dinner</th>
                      <td>Rice</td>
                      <td>Appam</td>
                      <td>Chappathi</td>
                      <td>Idiyappam</td>
                      <td>Dosha</td>
                      <td>Chicken Biriyani</td>
                      <td>Porotta</td>                      
                    </tr>
                  </tbody>
          </table>
          </div>
         
          <div style={{textAlign:'center', fontSize:23, fontWeight:630, marginTop:30}}> Date Selection </div>
          <div style={{marginTop:30}}>
          <Row>
           
                <Col xs={12} md={6} style={{marginTop: 20}}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                          <TextField
                              id="datetime-local"
                              label="From"
                              type="date"
                              defaultValue={year + '-' + month + '-' + date}
                              sx={{ width: '100%' }}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              onChange={(newValue) => {
                                  setDate1(newValue.target.value);
                              }}
                          />
                      </FormControl>
                  </Col>
                  <Col xs={12} md={6} style={{marginTop: 20}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                              <DesktopDatePicker className='deskpp'
                                label="To"
                                value={value2}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                  setValue2(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </Stack>
                      </LocalizationProvider>
                  </Col>
          </Row>
          </div>
          <div style={{textAlign:'center', marginTop:50 }}>
          <Button onClick={() => history.push('/Booking')} className='cntinu' style={{backgroundColor:'#7c003f', cursor:'pointer', width:200}}>continue</Button>
          </div>
           
        </Row>
      </Container>
  
    </div>
  )
}

export default Messbooking

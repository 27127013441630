import React from 'react'
import {Container, Row, Col, Carousel} from "react-bootstrap"
import foood from '../../Assets/Images/ab1.jpg'
import './Banner.css'
function Banner() {
  return (
    <div className='banner'>
        <Container>
            <Row>
              <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 img-hei"
                        src={foood}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 img-hei"
                        src={foood}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 img-hei"
                        src={foood}
                        alt="Third slide"
                    />
                </Carousel.Item>
              </Carousel>
            </Row>
        </Container>

    </div>
  )
}

export default Banner

import React from 'react'
import {Container, Row, Col, Form} from "react-bootstrap"
import './Footer.css'
import {MdLocationPin, MdCall } from "react-icons/md";
import {GrMail, GrSend } from "react-icons/gr"
import {FiSearch, FiSend} from 'react-icons/fi'

function Footer() {
  return (
      <div>
    <div className='footer' style={{ backgroundColor:'#efefef', padding: 20}}>
      <Container>
          <Row>
              <Col xs={12} md={6} lg={3}>
                <div>
                  <h6 className='fthead'> Contact us</h6>
                  <p className='cont'><MdCall  size={15} style={{ marginRight:5}}/>+91 123 456 789</p>
                  <p className='cont'><GrMail size={15} style={{ marginRight:5}}/>metrofood@gmail.com</p>
                  <p className='cont'><MdLocationPin size={15} style={{ marginRight:5}}/>kochi</p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={2}>
                <div>
                  <h6 className='fthead'> Policies & Info</h6>
                  <div className='cont'>
                  <p>Terms & Conditions</p>
                  <p>Policy for Sellers</p>
                  <p>Policy for Buyers</p>
                  <p>Shipping & Refund</p>
                  <p>Wholesale Policy</p>
                  </div>
                </div>
              </Col>
         
              <Col xs={12} md={6} lg={2}>
                <div>
                  <h6 className='fthead'> Quick Links</h6>
                  <div className='cont'>
                  <p>Seller Login</p>
                  <p>Seller Sign up</p>
                  <p>Seller Handbook</p>
                  <p>Seller control</p>
                  <p >Panel Seller FAQs</p>
                  </div> 
                </div>
              </Col>
              
              <Col xs={12} md={6} lg={2}>
                <div>
                  <h6 className='fthead'> My account</h6>
                  <div className='cont'>
                  <p>Accessories</p>
                  <p>Balcony Table</p>
                  <p>Bar Units</p>
                  <p>Beds</p>
                  <p>Bedside Tables</p>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6} lg={3}>
                <div>
                  <h6 className='fthead'> Subscribe now</h6>
                  <p className='cont'>country popuar loreum ipsum lorem  opposite lable </p> 

                  {/* <div className='emadd'>
                      <div className='sndbx' >
                          <Form.Control className='sendbox'
                          type="text"
                          id="inputPassword5"
                          placeholder='Your Email Address'
                          aria-describedby="passwordHelpBlock"
                          />
                        <div className='shareicon'>
                          <GrSend className='send' size={20} style={{marginTop: 8, borderColor:'white'}}/>
                        </div>
                      </div>
                  </div> */}
                  
                  <div className='location'>   
                <div className='searchbx'>
                    <Form.Control className='searchbox'
                    type="text"
                    id="inputPassword5"
                    placeholder='Your Email Address'
                    aria-describedby="passwordHelpBlock"
                    />
                </div>     
                <div className='searchicon'>
                    <FiSend size={20} style={{marginTop: 10, color:'white'}}/>
                </div>  
            </div>    
                </div> 
               
              </Col>
          </Row>
      </Container>
    </div>
    </div>
  )
}

export default Footer

